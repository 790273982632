import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Menubar } from '../../components/menubar/menubar';
import { MenubarItem } from '../../components/menubar/menubar-types';
import { Langbar } from '../langbar/langbar';
import { useIntl } from 'react-intl';

export function PublicMenu() {
  const history = useHistory();
  const intl = useIntl();

  const publicMenuItems: MenubarItem[] = useMemo(
    () => [
      {
        label: intl.formatMessage({
          id: 'KS_P_MENU_FAQ',
          defaultMessage: 'Časté dotazy',
        }),
        onClick: () => history.push('/faq'),
      },
      {
        label: intl.formatMessage({
          id: 'KS_MENU_INSTRUCTIONS',
          defaultMessage: 'Návod',
        }),
        onClick: () =>
          window.open('https://crr.gov.cz/kontakty/konzultacni-servis/', '_blank'),
      },
    ],
    [history, intl]
  );

  return <Menubar items={publicMenuItems} ActionComponent={Langbar} />;
}
