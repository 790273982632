import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { noop } from 'lodash';
import MuiTextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import MuiInputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import MuiSearchIcon from '@material-ui/icons/Search';
import MuiClearIcon from '@material-ui/icons/Clear';
import PrintIcon from '@material-ui/icons/Print';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  FormSelect,
  Form,
  Panel,
  FormAutocomplete,
  FormSubmitButton,
} from '@eas/common-web';
import { PublicPage } from '../../../components/public-page/public-page';
import { useFaq } from './faq-hook';
import { useStyles } from './faq-styles';
import { FaqItem } from './faq-item';
import {
  useProjectPhases,
  useNotices,
  useSpecificTargets,
  useActivities,
  useTags,
} from './faq-api';
import { FiltersDto } from './faq-types';
import { KsPublicFormLabel } from '../components/ks-public-form-label/ks-public-form-label';
import Divider from '@material-ui/core/Divider';

export function Faq() {
  const intl = useIntl();

  const {
    searchText,
    handleSearchTextChange,
    handleClear,
    handleClearFilters,
    handleChange,
    handleClickPrint,
    printing,
    showPrintButton,
    source,
    formRef,
  } = useFaq();
  const classes = useStyles();

  const projectPhases = useProjectPhases();
  const notices = useNotices();
  const specificTargets = useSpecificTargets();
  const activities = useActivities();
  const tags = useTags();

  return (
    <PublicPage
      title={
        <FormattedMessage
          id="KS_P_FAQ_TITLE"
          defaultMessage="Časté dotazy"
        />
      }
    >
      <FormattedMessage
        id="KS_P_FAQ_LABEL_EXPLANATION"
        defaultMessage="Výsledky dotazů se ihned zobrazí po zadání parametrů"
      />
      <Box height="20px" />

      <MuiTextField
        classes={{ root: classes.searchTextField }}
        autoComplete="off"
        id="outlined-search"
        type="search"
        margin="dense"
        placeholder={intl.formatMessage({
          id: 'KS_P_FAQ_BTN_SEARCH',
          defaultMessage: 'Vyhledat',
        })}
        variant="outlined"
        value={searchText}
        onChange={handleSearchTextChange}
        InputProps={{
          classes: { root: classes.searchTextFieldInput },
          endAdornment: (
            <MuiInputAdornment position="end">
              {searchText && (
                <MuiClearIcon
                  classes={{ root: classes.clearIcon }}
                  onClick={handleClear}
                />
              )}

              {source.loading ? (
                <CircularProgress
                  classes={{ root: classes.searchIcon }}
                  size="20px"
                  color="inherit"
                />
              ) : (
                <MuiSearchIcon classes={{ root: classes.searchIcon }} />
              )}
            </MuiInputAdornment>
          ),
          style: {
            boxShadow: 'none',
            paddingRight: 0,
          },
        }}
      />
      <Box height="20px" />
      <Panel
        label={
          <FormattedMessage
            id="KS_P_FAQ_FILTER_PANEL_TITLE"
            defaultMessage="Filtry"
          />
        }
        sideBorder={true}
      >
        <Box display="flex" flexDirection="row" width="100%" padding="16px">
          <Form<FiltersDto>
            editing={true}
            initialValues={{
              specificTargets: [],
              notices: [],
              projectPhases: [],
              activities: [],
              tags: [],
            }}
            onSubmit={noop}
            ref={formRef}
          >
            <Grid container>
              <Grid item xs={12} sm={2} className={classes.labelWrapper}>
                <KsPublicFormLabel
                  className={classes.label}
                  helpLabel={intl.formatMessage({
                    id: 'KS_P_FAQ_FIELD_HELP_SPECIFIC_TARGET',
                    defaultMessage: ' ',
                  })}
                >
                  <FormattedMessage
                    id="KS_P_FAQ_FIELD_LABEL_SPECIFIC_TAGRET"
                    defaultMessage="Specifický cíl"
                  />
                </KsPublicFormLabel>
              </Grid>
              <Grid item xs={12} sm={10}>
                <FormSelect
                  name="specificTargets"
                  multiple
                  labelOptions={{ hide: true }}
                  source={specificTargets}
                  tooltipMapper={(o) => o.name}
                  notifyChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Box height={5} />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={2} className={classes.labelWrapper}>
                <KsPublicFormLabel
                  className={classes.label}
                  helpLabel={intl.formatMessage({
                    id: 'KS_P_FAQ_FIELD_HELP_NOTICE',
                    defaultMessage: ' ',
                  })}
                >
                  <FormattedMessage
                    id="KS_P_FAQ_FIELD_LABEL_NOTICE"
                    defaultMessage="Výzva"
                  />
                </KsPublicFormLabel>
              </Grid>
              <Grid item xs={12} sm={10}>
                <FormSelect
                  name="notices"
                  multiple
                  labelOptions={{ hide: true }}
                  source={notices}
                  tooltipMapper={(o) => o.name}
                  notifyChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Box height={5} />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={2} className={classes.labelWrapper}>
                <KsPublicFormLabel
                  className={classes.label}
                  helpLabel={intl.formatMessage({
                    id: 'KS_P_FAQ_FIELD_HELP_PROJECT_PHASE',
                    defaultMessage: ' ',
                  })}
                >
                  <FormattedMessage
                    id="KS_P_FAQ_FIELD_LABEL_PROJECT_PHASE"
                    defaultMessage="Dotaz k fázi projektu"
                  />
                </KsPublicFormLabel>
              </Grid>
              <Grid item xs={12} sm={10}>
                <FormSelect
                  name="projectPhases"
                  multiple
                  labelOptions={{ hide: true }}
                  source={projectPhases}
                  tooltipMapper={(o) => o.name}
                  notifyChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Box height={5} />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={2} className={classes.labelWrapper}>
                <KsPublicFormLabel
                  className={classes.label}
                  helpLabel={intl.formatMessage({
                    id: 'KS_P_FAQ_FIELD_HELP_ACTIVITY',
                    defaultMessage: ' ',
                  })}
                >
                  <FormattedMessage
                    id="KS_P_FAQ_FIELD_LABEL_ACTIVITY"
                    defaultMessage="Aktivita"
                  />
                </KsPublicFormLabel>
              </Grid>
              <Grid item xs={12} sm={10}>
                <FormSelect
                  name="activities"
                  multiple
                  labelOptions={{ hide: true }}
                  source={activities}
                  tooltipMapper={(o) => o.name}
                  notifyChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Box height={5} />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={2} className={classes.labelWrapper}>
                <KsPublicFormLabel
                  className={classes.label}
                  helpLabel={intl.formatMessage({
                    id: 'KS_P_FAQ_FIELD_HELP_TAGS',
                    defaultMessage: ' ',
                  })}
                >
                  <FormattedMessage
                    id="KS_P_FAQ_FIELD_LABEL_TAGS"
                    defaultMessage="Klíčová slova"
                  />
                </KsPublicFormLabel>
              </Grid>
              <Grid item xs={12} sm={10}>
                <FormAutocomplete
                  name="tags"
                  multiple
                  labelOptions={{ hide: true }}
                  source={tags}
                  tooltipMapper={(o) => o.name}
                  notifyChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <Box display="flex" justifyContent="flex-end" marginTop={3}>
                  <FormSubmitButton
                    variant="outlined"
                    onClick={handleClearFilters}
                  >
                    {intl.formatMessage({
                      id: 'KS_P_FAQ_FIELD_BTN_CLEAR',
                      defaultMessage: 'Smazat filtry',
                    })}
                  </FormSubmitButton>
                  {showPrintButton && (
                    <>
                      <Divider orientation="vertical" />
                      <FormSubmitButton
                        variant="outlined"
                        onClick={handleClickPrint}
                        title={intl.formatMessage({
                          id: 'KS_P_FAQ_FIELD_BTN_PRINT',
                          defaultMessage: 'Exportovat',
                        })}
                      >
                        {printing ? (
                          <CircularProgress size="20px" color="inherit" />
                        ) : (
                          <PrintIcon />
                        )}
                      </FormSubmitButton>
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Form>
        </Box>
      </Panel>

      <Box height="50px" />
      {source.items.length === 0 && source.loading === false && (
        <>
          <FormattedMessage
            id="KS_P_FAQ_LABEL_NO_RESULT"
            defaultMessage="Nenašli se žádné dotazy splňující podmínky"
          />
        </>
      )}
      {source.items.map((item, i) => (
        <FaqItem key={i} item={item} />
      ))}
    </PublicPage>
  );
}
