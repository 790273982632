import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './footer-styles';
import crrLogo from './crr.svg';
import euLogo from './eu.svg';
import mmrLogo from './mmr2.svg';
import { FormattedMessage } from 'react-intl';

export function Footer() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.contacts}>
        <Grid container className={classes.contactsRoot}>
          <Grid item xs={false} sm={1} />
          <Grid item xs={12} sm={2}>
            <Typography variant="h6" className={classes.linksLabel}>
              <FormattedMessage
                id="KS_P_FOOTER_LINKS"
                defaultMessage="Užitečné odkazy:"
              />
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Box display="flex" justifyContent="space-around">
              <Typography>
                <Link
                  className={classes.link}
                  href="https://www.crr.gov.cz"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FormattedMessage
                    id="KS_P_FOOTER_LINKS_CRR"
                    defaultMessage="CRR"
                  />
                </Link>
              </Typography>
              <Typography align="right">
                <Link
                  className={classes.link}
                  href="https://www.mmr.cz"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FormattedMessage
                    id="KS_P_FOOTER_LINKS_MMR"
                    defaultMessage="MMR"
                  />
                </Link>
              </Typography>
              <Typography align="right">
                <Link
                  className={classes.link}
                  href="https://www.dotaceeu.cz"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FormattedMessage
                    id="KS_P_FOOTER_LINKS_EU"
                    defaultMessage="DOTACE EU"
                  />
                </Link>
              </Typography>
              <Typography className={classes.link} align="right">
                <Link
                  className={classes.link}
                  href="https://www.irop.mmr.cz"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FormattedMessage
                    id="KS_P_FOOTER_LINKS_IROP"
                    defaultMessage="IROP"
                  />
                </Link>
              </Typography>
              <Typography className={classes.link} align="right">
                <Link
                  className={classes.link}
                  href="https://mmr.gov.cz/cs/narodni-dotace"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FormattedMessage
                    id="KS_P_FOOTER_LINKS_NATIONAL_GRANTS"
                    defaultMessage="Národní dotace"
                  />
                </Link>
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={false} sm={1} />
        </Grid>
      </div>
      <div className={classes.logos}>
        <Grid container className={classes.logosRoot}>
          <Grid item xs={false} lg={1} />
          <Grid item xs={12} lg={8} className={classes.logoContainer}>
            <img src={euLogo} className={classes.euLogo} />
            <img src={mmrLogo} className={classes.mmrLogo} />
            <img src={crrLogo} className={classes.crrLogo} />
          </Grid>

          <Grid item md={false} lg={2} className={classes.logoText}>
            <Box display={{ xs: 'none', lg: 'block' }}>
              <Typography align="right">
                <FormattedMessage
                  id="KS_P_FOOTER_CONTACT_COPYRIGHT"
                  defaultMessage="Centrum pro regionální rozvoj České republiky © 2021"
                />
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={false} lg={1} />
        </Grid>
      </div>
    </div>
  );
}
