import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Form, FormTextField, FormSubmitButton } from '@eas/common-web';
import { PublicPage } from '../../../components/public-page/public-page';
import { LoginData } from '../../../common/models';
import { useLogin } from './login-hook';
import { useStyles } from './login-styles';
import { KsPublicFormItem } from '../components/ks-public-form-item/ks-public-form-item';

export function Login() {
  const intl = useIntl();

  const classes = useStyles();

  const {
    loading,
    formRef,
    handleSubmit,
    hiddenFormRef,
    hiddenUsernameRef,
    hiddenPasswordRef,
    hiddenCaptchaRef,
  } = useLogin();
  return (
    <PublicPage>
      <Typography className={classes.title} variant="h1">
        <FormattedMessage
          id="KS_P_LOGIN_MAIN_TITLE"
          defaultMessage="Konzultační servis"
        />
      </Typography>
      <Typography className={classes.warning}>
        <FormattedMessage id="KS_P_LOGIN_WARNING" defaultMessage=" " />
      </Typography>
      <Grid container>
        <Grid item xs={false} md={4} />
        <Grid item xs={12} md={4}>
          <Paper className={classes.paper} variant="outlined">
            <Form<LoginData>
              editing={!loading}
              initialValues={{} as any}
              onSubmit={handleSubmit}
              ref={formRef}
            >
              <Grid item xs={12}>
                <Box display="flex" flexDirection="column" alignItems="stretch">
                  <Typography variant="h2" className={classes.subtitle}>
                    <FormattedMessage
                      id="KS_P_LOGIN_SUBTITLE"
                      defaultMessage="Přihlášení"
                    />
                  </Typography>

                  <KsPublicFormItem
                    name="username"
                    className={classes.username}
                    helpLabel={intl.formatMessage({
                      id: 'KS_P_LOGIN_FIELD_HELP_EMAIL',
                      defaultMessage: ' ',
                    })}
                    label={
                      <FormattedMessage
                        id="KS_P_LOGIN_FIELD_LABEL_EMAIL"
                        defaultMessage="Email"
                      />
                    }
                  >
                    <FormTextField
                      id="username"
                      required
                      name="username"
                      autocomplete="username"
                    />
                  </KsPublicFormItem>

                  <KsPublicFormItem
                    name="password"
                    className={classes.password}
                    helpLabel={intl.formatMessage({
                      id: 'KS_P_LOGIN_FIELD_HELP_PASSWORD',
                      defaultMessage: ' ',
                    })}
                    label={
                      <FormattedMessage
                        id="KS_P_LOGIN_FIELD_LABEL_PASSWORD"
                        defaultMessage="Heslo"
                      />
                    }
                  >
                    <FormTextField
                      id="password"
                      required
                      name="password"
                      autocomplete="current-password"
                      type="password"
                    />
                  </KsPublicFormItem>
                  <FormSubmitButton
                    variant="contained"
                    color="primary"
                    type="submit"
                    disableElevation
                    disabled={loading}
                    className={classes.button}
                    startIcon={
                      loading && (
                        <CircularProgress size="20px" color="inherit" />
                      )
                    }
                  >
                    <FormattedMessage
                      id="KS_P_LOGIN_FIELD_BTN_LOGIN"
                      defaultMessage="Přihlásit"
                    />
                  </FormSubmitButton>

                  <Typography className={classes.or}>
                    <FormattedMessage
                      id="KS_P_LOGIN_TEXT_LOGIN_OR"
                      defaultMessage="nebo"
                    />
                  </Typography>

                  <Typography className={classes.or}>
                    <Link className={classes.register} href="/registration">
                      <FormattedMessage
                        id="KS_P_LOGIN_TEXT_LOGIN_REGISTER"
                        defaultMessage="Jste tu poprvé? Registrujte se"
                      />
                    </Link>
                  </Typography>

                  <Divider className={classes.divider} />
                  <Typography className={classes.reset}>
                    <Link href="/password-reset-request">
                      <FormattedMessage
                        id="KS_P_LOGIN_TEXT_FORGOTTEN_PASSWORD"
                        defaultMessage="Zapoměli jste heslo ?"
                      />
                    </Link>
                  </Typography>

                  <Divider className={classes.divider} />
                  <Typography className={classes.help}>
                    <FormattedMessage
                      id="KS_P_LOGIN_TEXT_HELP"
                      defaultMessage="V případě problémů s přihlášením kontaktujte podporu na "
                    />
                    <Link href="mailto:helpks@crr.gov.cz">helpks@crr.gov.cz</Link>
                  </Typography>
                </Box>
              </Grid>
            </Form>
            <form
              ref={hiddenFormRef}
              action="/api/ks/auth/requester"
              method="POST"
            >
              <input ref={hiddenUsernameRef} name="username" hidden />
              <input
                ref={hiddenPasswordRef}
                name="password"
                type="password"
                hidden
              />
              <input ref={hiddenCaptchaRef} name="captcha" hidden />
            </form>
          </Paper>
        </Grid>
        <Grid item xs={false} md={4} />
      </Grid>
    </PublicPage>
  );
}
